<template>
  <div class="pdf-doc">
    <div class="pdf-view">
      <!-- Ficha Tecnica -->
      <section class="pdf">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo" />
          </div>
          <div class="title-container">
            <div>
              <strong>
                {{ titleCompany }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleVessel }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleFromItinerary }}
              </strong>
            </div>
            <div style="width: 82%">
              <table style="width: 100%;">
                <thead>
                  <tr>
                    <th>{{$t('label.type')+': '+itinerarySelected.TpVesselName}}</th>
                    <th>{{'MSSI: '+itinerarySelected.Mmsi}}</th>
                    <th>{{$t('label.gross')+': '+itinerarySelected.Gross}}</th>
                    <th>{{'TEUS: '+itinerarySelected.VesselTeus}}</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>
        <section class="body">
          <div class="general-info">
            <div class="info-content">
              <div class="info-gantt">
                <div style="width: 100%;">
                  <div style="height: calc(100vh - 52px); width: 100%;" ref="ganttContainer"></div>
                </div>
              </div>
              <!-- <div style="width: 100%;" v-if="NotProgrammed.length<9">
                <table class="table table-bordered mt-2 size-06">
                  <thead>
                      <tr>
                        <th class="text-center text-dark Gray">{{ $t('label.ActivitiesNotExecuted') }}</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="item in NotProgrammed" :key="item.targetid">
                        <td>{{ $i18n.locale=='en' ? item.textEn : item.textEs }}</td>
                      </tr>
                  </tbody>
                </table>
              </div> -->
            </div>
          </div>
        </section>
        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page")+': '+(1) }} {{ $t("label.of") }} {{ TotalPage }}
            </p>
          </div>
        </section>
      </section>
      <section class="pdf" v-if="NotProgrammed.length!=0">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo" />
          </div>
          <div class="title-container">
            <div>
              <strong>
                {{ titleCompany }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleVessel }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleFromItinerary }}
              </strong>
            </div>
            <div style="width: 82%">
              <table style="width: 100%;">
                <thead>
                  <tr>
                    <th>{{$t('label.type')+': '+itinerarySelected.TpVesselName}}</th>
                    <th>{{'MSSI: '+itinerarySelected.Mmsi}}</th>
                    <th>{{$t('label.gross')+': '+itinerarySelected.Gross}}</th>
                    <th>{{'TEUS: '+itinerarySelected.VesselTeus}}</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>
        <section class="body">
          <div class="general-info">
            <div class="info-content">
              <div style="width: 100%;">
                <table class="table table-bordered mt-2">
                  <thead>
                    <tr>
                      <th class="text-center text-dark Gray">{{ $t('label.ActivitiesNotExecuted') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in NotProgrammed" :key="item.targetid">
                      <td>{{ $i18n.locale=='en' ? item.textEn : item.textEs }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page")+': '+(2) }} {{ $t("label.of") }} {{ TotalPage }}
            </p>
          </div>
        </section>
      </section>
      <!-- ///// RESUMEN DE VISITA ////// -->
      <section class="pdf">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo" />
          </div>
          <div class="title-container">
            <div>
              <strong>
                {{ titleCompany }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleVessel }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleFromItinerary }}
              </strong>
            </div>
            <div style="width: 82%">
              <table style="width: 100%;">
                <thead>
                  <tr>
                    <th>{{$t('label.type')+': '+itinerarySelected.TpVesselName}}</th>
                    <th>{{'MSSI: '+itinerarySelected.Mmsi}}</th>
                    <th>{{$t('label.gross')+': '+itinerarySelected.Gross}}</th>
                    <th>{{'TEUS: '+itinerarySelected.VesselTeus}}</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>
        <section class="body">
          <div class="general-info">
            <div class="info-content">
              <div>
                <table class="table table-bordered text-center" >
                  <tbody>
                      <tr>
                          <th class="Gray">{{ getName(VisitSummaryJson) }}</th>
                      </tr>
                      <tr>
                          <td>
                              <div style="font-size: 15px !important;" class="d-flex justify-content-center flex-row">
                                <label class="col-sm-12 m-0"><b>{{getHour(VisitSummaryJson.HoursDs)}}</b> HRS 
                                  <b class="m-0">
                                    {{getPercentage(VisitSummaryJson.Percentage)}}% 
                                  </b>
                                  <b class="m-0" v-if="FgDate">
                                    {{`${getDate(VisitSummaryJson.TransactionBegin)} ${getDateHour(VisitSummaryJson.TransactionBegin)} - ${getDate(VisitSummaryJson.TransactionFinish)} ${getDateHour(VisitSummaryJson.TransactionFinish)}`}}
                                  </b>
                                </label> 
                              </div>
                          </td>
                      </tr>
                      <tr v-if="VisitSummaryJson.VisitSummaryDetailJson&&VisitSummaryJson.VisitSummaryDetailJson.length!=0">
                          <td class="p-0">
                              <table class="time-in-port-table mb-0" style="height: 100% !important">
                                  <tbody>
                                      <tr>
                                          <td class="p-0" v-for="Lv2 in VisitSummaryJson.VisitSummaryDetailJson" :key="Lv2.IdX"
                                              :style="`overflow: hidden !important; border-top: none !important; ${Lv2.FgBerth?'width: 52% !important; max-width: 52% !important;':'width: 12% !important; max-width: 12% !important;'}`">
                                              <div :style="`min-height: 35px !important; ${Lv2.FgBerth?'font-size: 12px !important; background: #749bd3 !important;':Lv2.FgOtherTime?'background: #5d5d5d !important; color: #fff !important;':'background: #e6e6e6 !important;'}`" class="p-1 d-flex align-items-center justify-content-center">
                                                <b>{{ getName(Lv2) }}</b>
                                              </div>
                                              <div>
                                                <label style="font-size: 10px !important;" class="p-1 col-sm-12 m-0"><b>{{getHour(Lv2.HoursDs)}}</b> HRS</label>
                                                <h5 class="m-0">{{getPercentage(Lv2.Percentage)}}%</h5>
                                              </div>
                                              <div v-if="FgDate">
                                                <label style="font-size: 9px !important;" class="px-1 col-sm-12 m-0"><b>{{`${getDate(Lv2.TransactionBegin)} ${getDateHour(Lv2.TransactionBegin)}`}}</b></label> 
                                                <label style="font-size: 9px !important;" class="px-1 col-sm-12 m-0"><b>{{`${getDate(Lv2.TransactionFinish)} ${getDateHour(Lv2.TransactionFinish)}`}}</b></label>
                                              </div>
                                              <div style="height: 100% !important" class="col-sm-12 px-0" v-if="Lv2.DetailJson&&Lv2.DetailJson.length!=0">
                                                  <table class="time-in-port-table mb-0" style="height: 100% !important">
                                                      <tbody>
                                                          <tr>
                                                              <td class="p-0" v-for="Lv3 in Lv2.DetailJson" :key="Lv3.IdX">
                                                                  <div :style="`min-height: 44px !important; ${Lv3.FgService? 'background: #52a3a6 !important; color: #fff !important;':Lv3.FgOtherTime?'background: #5d5d5d !important; color: #fff !important;':'background: #f88e86 !important;'}`" class="p-1 d-flex align-items-center justify-content-center">
                                                                    <b>{{ getName(Lv3) }}</b>
                                                                  </div>
                                                                  <div style="min-height: 121px;" class="d-flex align-items-center justify-content-center">
                                                                    <div>
                                                                      <div>
                                                                        <label style="font-size: 10px !important;" class="p-0 col-sm-12 m-0"><b>{{getHour(Lv3.HoursDs)}}</b></label> 
                                                                        <label style="font-size: 8px !important;" class="p-0 col-sm-12 m-0">HRS</label>
                                                                        <h6 class="m-0">{{getPercentage(Lv3.Percentage)}}%</h6>
                                                                      </div>
                                                                      <div v-if="FgDate">
                                                                        <label style="font-size: 8px !important;" class="px-1 col-sm-12 m-0"><b>{{`${getDate(Lv3.TransactionBegin)}`}}</b></label>
                                                                        <label style="font-size: 8px !important;" class="px-1 col-sm-12 m-0"><b>{{`${getDateHour(Lv3.TransactionBegin)}`}}</b></label>  
                                                                        <label style="font-size: 8px !important;" class="px-1 col-sm-12 m-0"><b>{{`${getDate(Lv3.TransactionFinish)}`}}</b></label>
                                                                        <label style="font-size: 8px !important;" class="px-1 col-sm-12 m-0"><b>{{`${getDateHour(Lv3.TransactionFinish)}`}}</b></label>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div style="height: 100% !important" class="col-sm-12 px-0" v-if="Lv3.DetailJson&&Lv3.DetailJson.length!=0">
                                                                      <table style="height: 100% !important" class="time-in-port-table mb-0">
                                                                          <tbody>
                                                                              <tr>
                                                                                  <td class="p-0" v-for="Lv4 in Lv3.DetailJson" :key="Lv4.IdX">
                                                                                      <div :style="`min-height: 30px !important; font-size: 7px !important; ${Lv3.FgService? 'background: #52a3a6 !important; color: #fff; !important' : 'background: #f7c0a3 !important;'}`" class="p-1 d-flex align-items-center justify-content-center">
                                                                                        <b>{{ getName(Lv4) }}</b>
                                                                                      </div>
                                                                                      <div style="min-height: 121px;" class="d-flex align-items-center justify-content-center">
                                                                                        <div>
                                                                                          <div>
                                                                                            <label style="font-size: 10px !important;" class="p-0 col-sm-12 m-0"><b>{{getHour(Lv4.HoursDs)}}</b></label> 
                                                                                            <label style="font-size: 8px !important;" class="p-0 col-sm-12 m-0">HRS</label>
                                                                                            <h6 class="m-0">{{getPercentage(Lv4.Percentage)}}%</h6>
                                                                                          </div>
                                                                                          <div v-if="FgDate">
                                                                                            <label style="font-size: 7px !important;" class="px-1 col-sm-12 m-0"><b>{{`${getDate(Lv4.TransactionBegin)}`}}</b></label> 
                                                                                            <label style="font-size: 7px !important;" class="px-1 col-sm-12 m-0"><b>{{`${getDateHour(Lv4.TransactionBegin)}`}}</b></label>
                                                                                            <label style="font-size: 7px !important;" class="px-1 col-sm-12 m-0"><b>{{`${getDate(Lv4.TransactionFinish)}`}}</b></label>
                                                                                            <label style="font-size: 7px !important;" class="px-1 col-sm-12 m-0"><b>{{`${getDateHour(Lv4.TransactionFinish)}`}}</b></label>
                                                                                          </div>
                                                                                        </div>
                                                                                      </div>
                                                                                      <div style="height: 100% !important" class="col-sm-12 px-0" v-if="Lv4.DetailJson&&Lv4.DetailJson.length!=0">
                                                                                          <table style="height: 100% !important" class="time-in-port-table mb-0">
                                                                                              <tbody>
                                                                                                  <tr>
                                                                                                      <td class="p-0" v-for="Lv5 in Lv4.DetailJson" :key="Lv5.IdX">
                                                                                                          <div :style="`min-height: 39px !important; font-size: 7px !important; ${Lv3.FgService? 'background: #52a3a6 !important; color: #fff; !important' : 'background: #749bd3 !important;'}`" class="p-1 d-flex align-items-center justify-content-center">
                                                                                                              <b>{{ getName(Lv5) }}</b>
                                                                                                          </div>
                                                                                                          <div>
                                                                                                            <label style="font-size: 10px !important;" class="p-0 col-sm-12 m-0"><b>{{getHour(Lv5.HoursDs)}}</b></label> 
                                                                                                            <label style="font-size: 8px !important;" class="p-0 col-sm-12 m-0">HRS</label>
                                                                                                            <h6 class="m-0">{{getPercentage(Lv5.Percentage)}}%</h6>
                                                                                                          </div>
                                                                                                          <div v-if="!Lv5.FgDelay&&FgDate">
                                                                                                            <label style="font-size: 7px !important;" class="px-1 col-sm-12 m-0"><b>{{`${getDate(Lv5.TransactionBegin)}`}}</b></label> 
                                                                                                            <label style="font-size: 7px !important;" class="px-1 col-sm-12 m-0"><b>{{`${getDateHour(Lv5.TransactionBegin)}`}}</b></label>
                                                                                                            <label style="font-size: 7px !important;" class="px-1 col-sm-12 m-0"><b>{{`${getDate(Lv5.TransactionFinish)}`}}</b></label>
                                                                                                            <label style="font-size: 7px !important;" class="px-1 col-sm-12 m-0"><b>{{`${getDateHour(Lv5.TransactionFinish)}`}}</b></label>
                                                                                                          </div>
                                                                                                      </td>
                                                                                                  </tr>
                                                                                              </tbody>
                                                                                          </table>
                                                                                      </div>
                                                                                  </td>
                                                                              </tr>
                                                                          </tbody>
                                                                      </table>
                                                                  </div>
                                                              </td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page")+': '+CurrentPage(2) }} {{ $t("label.of") }} {{ TotalPage }}
            </p>
          </div>
        </section>
      </section>
      <!-- ///// PRODUCTIVIDAD POR DIA Y CORTE ////// -->
      <section class="pdf" v-for="(LapsePage, index) in ProdByDayLapseIndex">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo" />
          </div>
          <div class="title-container">
            <div>
              <strong>
                {{ titleCompany }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleVessel }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleFromItinerary }}
              </strong>
            </div>
            <div style="width: 82%">
              <table style="width: 100%;">
                <thead>
                  <tr>
                    <th>{{$t('label.type')+': '+itinerarySelected.TpVesselName}}</th>
                    <th>{{'MSSI: '+itinerarySelected.Mmsi}}</th>
                    <th>{{$t('label.gross')+': '+itinerarySelected.Gross}}</th>
                    <th>{{'TEUS: '+itinerarySelected.VesselTeus}}</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>
        <section class="body">
          <div class="general-info">
            <div class="info-content">
              <div>
                <table class="table table-bordered text-center">
                  <tbody>
                    <tr>
                      <td>
                        <h2>{{ $t('label.OperationSituationOptions.Productivity') }}</h2>
                      </td>
                      <td class="text-left" style="background: #d9e2f3 !important;">
                        <CCol>
                          <strong>{{ $t('label.MovementsPerHourNet') }}:
                            {{ ProdByDayLapseHeaderJson[0]?.MovsPerHourNet?ProdByDayLapseHeaderJson[0].MovsPerHourNet:0 }}
                          </strong>
                        </CCol>
                        <CCol>
                          <strong>{{ $t('label.MovementsPerHourGross') }}:
                            {{ ProdByDayLapseHeaderJson[0]?.MovsPerHourGross?ProdByDayLapseHeaderJson[0].MovsPerHourGross:0 }}
                          </strong>
                        </CCol>
                        <CCol>
                          <strong>{{ $t('label.MovementsPerHourTerminal') }}:
                            {{ ProdByDayLapseHeaderJson[0]?.MovsPerHourTerminal?ProdByDayLapseHeaderJson[0].MovsPerHourTerminal:0 }}
                          </strong>
                        </CCol>
                      </td>                                                                                             
                    </tr>
                  </tbody>
                </table>
                <table class="table table-bordered text-center">
                  <thead>
                    <tr>
                      <th rowspan="2" style="width: 25%;" class="Gray">
                        <label class="mb-3">{{ $t('label.lapse') }}</label>
                      </th>
                      <th :colspan="QuantityCrane" class="text-white" style="background: #1f3864 !important; width: 25%;">{{ $t('label.movement') }}</th>
                      <th :colspan="QuantityCrane" class="text-white" style="background: #ed7d31 !important; width: 25%;">{{ $t('label.Timeslost') }}</th>
                      <th rowspan="2" style="width: 25%;" class="Gray">
                        <label class="mb-3">{{ $t('label.Percentage') }} (%)</label>
                      </th>
                    </tr>
                    <tr>
                      <th v-for="Movement in ProdByDayLapseDetailJson[0]?.MovementsJson" class="Gray">
                        {{ Movement.CraneAlias }}
                      </th>
                      <th v-for="Delay in ProdByDayLapseDetailJson[0]?.DelaysJson" class="Gray">
                        {{ Delay.CraneAlias }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in ProdByDayLapseIndex[index]?.DetailJson">
                      <td>{{ item?.LapseDateHourRange }}</td>
                      <td v-for="Movement in item?.MovementsJson">
                        {{ Movement?.CantMovs }}
                      </td>
                      <td v-for="Delay in item?.DelaysJson">
                        {{ Delay?.DelaysDs }}
                      </td>
                      <td>{{ item?.Percentage }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page")+': '+CurrentPage(index+3) }} {{ $t("label.of") }} {{ TotalPage }}
            </p>
          </div>
        </section>
      </section>
      <!-- ///// TAMAÑO Y TIPO ////// -->
      <section class="pdf" v-if="BulkCarrier==1">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo" />
          </div>
          <div class="title-container">
            <div>
              <strong>
                {{ titleCompany }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleVessel }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleFromItinerary }}
              </strong>
            </div>
            <div style="width: 82%">
              <table style="width: 100%;">
                <thead>
                  <tr>
                    <th>{{$t('label.type')+': '+itinerarySelected.TpVesselName}}</th>
                    <th>{{'MSSI: '+itinerarySelected.Mmsi}}</th>
                    <th>{{$t('label.gross')+': '+itinerarySelected.Gross}}</th>
                    <th>{{'TEUS: '+itinerarySelected.VesselTeus}}</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>
        <section class="body">
          <div class="general-info">
            <div class="info-content">
              <div class="title-report">
                {{ 'TAMAÑO Y TIPO' }}
              </div>
              <div>
                <table class="table table-bordered text-center">
                  <thead>
                    <tr>
                      <th rowspan="3" style="width: 10%;"><label class="mb-4">{{ $t('label.size') }}</label></th>
                      <th colspan="6" class="Gray">TOTAL</th>
                    </tr>
                    <tr class="text-white">
                      <th colspan="2" style="background: #1f3864 !important;">{{ $t('label.DISCHARGE') }}</th>
                      <th colspan="2" style="background: #ed7d31 !important;">{{ $t('label.shipment') }}</th>
                      <th colspan="2" style="background: #1da9a2 !important;">{{ $t('label.mobilized') }}</th>
                    </tr>
                    <tr>
                      <th>{{ $t('label.full') }}</th>
                      <th>{{ $t('label.empty') }}</th>
                      <th>{{ $t('label.full') }}</th>
                      <th>{{ $t('label.empty') }}</th>
                      <th>{{ $t('label.ship_ship') }}</th>
                      <th>{{ $t('label.ship_land_ship') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in SizeJson" :key="item.IdX">
                      <td :style="item.SizeDs=='TOTAL'?'font-weight: bold;':''">
                        {{ item.SizeDs }}
                      </td>
                      <td :style="item.SizeDs=='TOTAL'?'font-weight: bold;':''">
                        {{ item.DischargeJson[0].CantFull }}
                      </td>
                      <td :style="item.SizeDs=='TOTAL'?'font-weight: bold;':''">
                        {{ item.DischargeJson[0].CantEmpty }}
                      </td>
                      <td :style="item.SizeDs=='TOTAL'?'font-weight: bold;':''">
                        {{ item.LoadJson[0].CantFull }}
                      </td>
                      <td :style="item.SizeDs=='TOTAL'?'font-weight: bold;':''">
                        {{ item.LoadJson[0].CantEmpty }}
                      </td>
                      <td :style="item.SizeDs=='TOTAL'?'font-weight: bold;':''">
                        {{ item.RestowJson[0].CantShipShip }}
                      </td>
                      <td :style="item.SizeDs=='TOTAL'?'font-weight: bold;':''">
                        {{ item.RestowJson[0].CantShipLandShip }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="table table-bordered text-center">
                  <thead>
                    <tr class="text-white">
                      <th style="color: #3c4b64; width: 14%;" rowspan="2"><label class="mb-3">{{ $t('label.movement') }}</label></th>
                      <th colspan="4" style="width: 17.2%;background: #3492b0 !important;">{{ $t('label.Estandar') }}</th>
                      <th colspan="4" style="width: 17.2%;background: #da8368 !important;">HIGH CUBE</th>
                      <th colspan="4" style="width: 17.2%;background: #18cdd6 !important;">{{ $t('label.Reefers') }}</th>
                      <th colspan="4" style="width: 17.2%;background: #ffc000 !important;">{{ $t('label.special') }}</th>
                      <th colspan="4" style="width: 17.2%;background: #92cddc !important;">{{ $t('label.Tank') }}</th>
                    </tr>
                    <tr style="font-size: 10px;">
                      <th>20 FT</th>
                      <th>40 FT</th>
                      <th>45 FT</th>
                      <th>TOTAL</th>
                      <th>20 FT</th>
                      <th>40 FT</th>
                      <th>45 FT</th>
                      <th>TOTAL</th>
                      <th>20 FT</th>
                      <th>40 FT</th>
                      <th>45 FT</th>
                      <th>TOTAL</th>
                      <th>20 FT</th>
                      <th>40 FT</th>
                      <th>45 FT</th>
                      <th>TOTAL</th>
                      <th>20 FT</th>
                      <th>40 FT</th>
                      <th>45 FT</th>
                      <th>TOTAL</th>
                    </tr>
                  </thead>
                  <tbody style="font-size: 10px;">
                    <tr v-for="item in TypeJson">
                      <td class="text-left">{{ $i18n.locale=='en'?item.MovementDsEn:item.MovementDsEs }}</td>
                      <td>{{ item.StandardJson[0].Cant20?item.StandardJson[0].Cant20:0 }}</td>
                      <td>{{ item.StandardJson[0].Cant40?item.StandardJson[0].Cant40:0 }}</td>
                      <td>{{ item.StandardJson[0].Cant45?item.StandardJson[0].Cant45:0 }}</td>
                      <td><strong>{{ item.StandardJson[0].CantTotal?item.StandardJson[0].CantTotal:0 }}</strong></td>
                      <td>{{ item.HighCubeJson[0].Cant20?item.HighCubeJson[0].Cant20:0 }}</td>
                      <td>{{ item.HighCubeJson[0].Cant40?item.HighCubeJson[0].Cant40:0 }}</td>
                      <td>{{ item.HighCubeJson[0].Cant45?item.HighCubeJson[0].Cant45:0 }}</td>
                      <td><strong>{{ item.HighCubeJson[0].CantTotal?item.HighCubeJson[0].CantTotal:0 }}</strong></td>
                      <td>{{ item.ReeferJson[0].Cant20?item.ReeferJson[0].Cant20:0 }}</td>
                      <td>{{ item.ReeferJson[0].Cant40?item.ReeferJson[0].Cant40:0 }}</td>
                      <td>{{ item.ReeferJson[0].Cant45?item.ReeferJson[0].Cant45:0 }}</td>
                      <td><strong>{{ item.ReeferJson[0].CantTotal?item.ReeferJson[0].CantTotal:0 }}</strong></td>
                      <td>{{ item.SpecialJson[0].Cant20?item.SpecialJson[0].Cant20:0 }}</td>
                      <td>{{ item.SpecialJson[0].Cant40?item.SpecialJson[0].Cant40:0 }}</td>
                      <td>{{ item.SpecialJson[0].Cant45?item.SpecialJson[0].Cant45:0 }}</td>
                      <td><strong>{{ item.SpecialJson[0].CantTotal?item.SpecialJson[0].CantTotal:0 }}</strong></td>
                      <td>{{ item.TankJson[0].Cant20?item.TankJson[0].Cant20:0 }}</td>
                      <td>{{ item.TankJson[0].Cant40?item.TankJson[0].Cant40:0 }}</td>
                      <td>{{ item.TankJson[0].Cant45?item.TankJson[0].Cant45:0 }}</td>
                      <td><strong>{{ item.TankJson[0].CantTotal?item.TankJson[0].CantTotal:0 }}</strong></td>
                    </tr>
                    <tr>
                      <td class="text-left"><strong>TOTAL</strong></td>
                      <td><strong>{{ TotalType.StandardCant20 }}</strong></td>
                      <td><strong>{{ TotalType.StandardCant40 }}</strong></td>
                      <td><strong>{{ TotalType.StandardCant45 }}</strong></td>
                      <td><strong>{{ TotalType.StandardCantTotal }}</strong></td>
                      <td><strong>{{ TotalType.HighCubeCant20 }}</strong></td>
                      <td><strong>{{ TotalType.HighCubeCant40 }}</strong></td>
                      <td><strong>{{ TotalType.HighCubeCant45 }}</strong></td>
                      <td><strong>{{ TotalType.HighCubeCantTotal }}</strong></td>
                      <td><strong>{{ TotalType.ReeferCant20 }}</strong></td>
                      <td><strong>{{ TotalType.ReeferCant40 }}</strong></td>
                      <td><strong>{{ TotalType.ReeferCant45 }}</strong></td>
                      <td><strong>{{ TotalType.ReeferCantTotal }}</strong></td>
                      <td><strong>{{ TotalType.SpecialCant20 }}</strong></td>
                      <td><strong>{{ TotalType.SpecialCant40 }}</strong></td>
                      <td><strong>{{ TotalType.SpecialCant45 }}</strong></td>
                      <td><strong>{{ TotalType.SpecialCantTotal }}</strong></td>
                      <td><strong>{{ TotalType.TankCant20 }}</strong></td>
                      <td><strong>{{ TotalType.TankCant40 }}</strong></td>
                      <td><strong>{{ TotalType.TankCant45 }}</strong></td>
                      <td><strong>{{ TotalType.TankCantTotal }}</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <CRow>
                <div style="width: 50%;" class="pl-3">
                  <CRow>
                    <div style="width: 60%;">
                      <simpleDonut
                        :Animations="false"
                        :dataLabels="false"
                        :Series="SeriesSizeType(TypeJson[0])" 
                        :Colors="ColorSizeType" 
                        :Categories="LabelsSizeType"
                        :Tooltip="TooltipSizeType"
                        Height="190px"
                      />
                    </div>
                    <div style="width: 40%;" class="d-flex justify-content-center">
                      <div style="width: 100%;">
                        <table style="width: 100%;">
                          <tbody>
                            <tr>
                              <td></td>
                              <td class="text-center"><strong>{{ $t('label.DISCHARGE') }}</strong></td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center justify-content-center">
                                  <span class="circle mr-1" style="background: #3492b0 !important;"></span>
                                </div>
                              </td>
                              <td>{{$t('label.Estandar')}}: {{ PercentageDischarge(TypeJson[0]?.StandardJson[0]) }}</td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center justify-content-center">
                                  <span class="circle mr-1" style="background: #da8368 !important;"></span>
                                </div>
                              </td>
                              <td>HIGH CUBE: {{ PercentageDischarge(TypeJson[0]?.HighCubeJson[0]) }}</td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center justify-content-center">
                                  <span class="circle mr-1" style="background: #18cdd6 !important;"></span>
                                </div>
                              </td>
                              <td>{{ $t('label.Reefers') }}: {{ PercentageDischarge(TypeJson[0]?.ReeferJson[0]) }}</td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center justify-content-center">
                                  <span class="circle mr-1" style="background: #ffc000;"></span>
                                </div>
                              </td>
                              <td>{{ $t('label.special') }}: {{ PercentageDischarge(TypeJson[0]?.SpecialJson[0]) }}</td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center justify-content-center">
                                  <span class="circle mr-1" style="background: #92cddc;"></span>
                                </div>
                              </td>
                              <td>{{ $t('label.Tank') }}: {{ PercentageDischarge(TypeJson[0]?.TankJson[0]) }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </CRow>
                </div>
                <div style="width: 50%;" class="pr-3">
                  <CRow>
                    <div style="width: 60%;">
                      <simpleDonut
                        :Animations="false"
                        :dataLabels="false"
                        :Series="SeriesSizeType(TypeJson[1])" 
                        :Colors="ColorSizeType" 
                        :Categories="LabelsSizeType"
                        :Tooltip="TooltipSizeType"
                        Height="190px"
                      />
                    </div>
                    <div style="width: 40%;" class="d-flex justify-content-center">
                      <div style="width: 100%;">
                        <table style="width: 100%;">
                          <tbody>
                            <tr>
                              <td></td>
                              <td class="text-center"><strong>{{ $t('label.LOAD') }}</strong></td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center justify-content-center">
                                  <span class="circle mr-1" style="background: #3492b0 !important;"></span>
                                </div>
                              </td>
                              <td>{{$t('label.Estandar')}}: {{ PercentageLoad(TypeJson[1]?.StandardJson[0]) }}</td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center justify-content-center">
                                  <span class="circle mr-1" style="background: #da8368 !important;"></span>
                                </div>
                              </td>
                              <td>HIGH CUBE: {{ PercentageLoad(TypeJson[1]?.HighCubeJson[0]) }}</td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center justify-content-center">
                                  <span class="circle mr-1" style="background: #18cdd6 !important;"></span>
                                </div>
                              </td>
                              <td>{{ $t('label.Reefers') }}: {{ PercentageLoad(TypeJson[1]?.ReeferJson[0]) }}</td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center justify-content-center">
                                  <span class="circle mr-1" style="background: #ffc000;"></span>
                                </div>
                              </td>
                              <td>{{ $t('label.special') }}: {{ PercentageLoad(TypeJson[1]?.SpecialJson[0]) }}</td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center justify-content-center">
                                  <span class="circle mr-1" style="background: #92cddc;"></span>
                                </div>
                              </td>
                              <td>{{ $t('label.Tank') }}: {{ PercentageLoad(TypeJson[1]?.TankJson[0]) }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </CRow>
                </div>
              </CRow>
            </div>
          </div>
        </section>
        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page")+': '+CurrentPage(4, true) }} {{ $t("label.of") }} {{ TotalPage }}
            </p>
          </div>
        </section>
      </section>
      <!-- ///// Shipping line ////// -->
      <section class="pdf" v-if="BulkCarrier==1">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo" />
          </div>
          <div class="title-container">
            <div>
              <strong>
                {{ titleCompany }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleVessel }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleFromItinerary }}
              </strong>
            </div>
            <div style="width: 82%">
              <table style="width: 100%;">
                <thead>
                  <tr>
                    <th>{{$t('label.type')+': '+itinerarySelected.TpVesselName}}</th>
                    <th>{{'MSSI: '+itinerarySelected.Mmsi}}</th>
                    <th>{{$t('label.gross')+': '+itinerarySelected.Gross}}</th>
                    <th>{{'TEUS: '+itinerarySelected.VesselTeus}}</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>
        <section class="body">
          <div class="general-info">
            <div class="info-content">
              <div class="title-report">
                {{ $t('label.newShippingLine')+', '+$t('label.sizes')+' '+$t('label.and')+' '+$t('label.PortActivities') }}
              </div>
              <CRow>
                <div style="border-right: 1px solid #d8dbe0;width: 50%;">
                  <div style="width: 100%;">
                    <barVerticalNew 
                      :series="seriesLineSize"
                      :labels="categoriesBar"
                      :titleYaxis="$t('label.QuantityContainers')"
                      :bandera="true"
                      height="250px"
                    >
                    </barVerticalNew>
                  </div>
                </div>
                <div style="width: 50%;">
                  <div style="width: 100%;">
                    <barHorizontalNew
                      colorDatalabels="#FFF"
                      :series="seriesLinePortActivity"
                      :labels="categoriesBar"
                      :titleXaxis="$t('label.QuantityContainers')"
                      :bandera="true"
                      height="250px"
                    >
                    </barHorizontalNew>
                  </div>
                </div>
              </CRow>
              <table class="table table-bordered text-center">
                <thead>
                  <tr class="text-white">
                    <th class="Gray"></th>
                    <th style="background: #00343f !important;">20 FT</th>
                    <th style="background: #ffbf67 !important;">40 FT</th>
                    <th style="background: #e9b7a6 !important;">45 FT</th>
                    <th style="background: #52a3a6 !important;">{{ this.$t('label.Export') }}</th>
                    <th style="background: #606060 !important;">{{ this.$t('label.Import') }}</th>
                    <th style="background: #5e548e !important;">{{ this.$t('label.ship_land_ship') }}</th>
                    <th style="background: #007a7b !important;">{{ this.$t('label.ship_ship') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in ShippingLineJson" :key="item.IdX">
                    <td class="Gray">{{ item.ShippingLineCode }}</td>
                    <td>{{ item.Cant20Ft }}</td>
                    <td>{{ item.Cant40Ft }}</td>
                    <td>{{ item.Cant45Ft }}</td>
                    <td>{{ item.CantExport }}</td>
                    <td>{{ item.CantImport }}</td>
                    <td>{{ item.CantShipLandShip }}</td>
                    <td>{{ item.CantShipShip }}</td>
                  </tr>
                  <tr>
                    <td class="Gray"><strong>TOTAL</strong></td>
                    <td><strong>{{ TotalShippingLine.Cant20Ft }}</strong></td>
                    <td><strong>{{ TotalShippingLine.Cant40Ft }}</strong></td>
                    <td><strong>{{ TotalShippingLine.Cant45Ft }}</strong></td>
                    <td><strong>{{ TotalShippingLine.CantExport }}</strong></td>
                    <td><strong>{{ TotalShippingLine.CantImport }}</strong></td>
                    <td><strong>{{ TotalShippingLine.CantShipLandShip }}</strong></td>
                    <td><strong>{{ TotalShippingLine.CantShipShip }}</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page")+': '+CurrentPage(5, true) }} {{ $t("label.of") }} {{ TotalPage }}
            </p>
          </div>
        </section>
      </section>
      <!-- ///// PlannedVsExecute ////// -->
      <section class="pdf" v-if="BulkCarrier==1">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo" />
          </div>
          <div class="title-container">
            <div>
              <strong>
                {{ titleCompany }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleVessel }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleFromItinerary }}
              </strong>
            </div>
            <div style="width: 82%">
              <table style="width: 100%;">
                <thead>
                  <tr>
                    <th>{{$t('label.type')+': '+itinerarySelected.TpVesselName}}</th>
                    <th>{{'MSSI: '+itinerarySelected.Mmsi}}</th>
                    <th>{{$t('label.gross')+': '+itinerarySelected.Gross}}</th>
                    <th>{{'TEUS: '+itinerarySelected.VesselTeus}}</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>
        <section class="body">
          <div class="general-info">
            <div class="info-content">
              <div class="title-report">
                {{ $t('label.MovementsPlannedVsExecuted') }}
              </div>
              <div>
                <table class="table table-bordered text-center">
                  <thead>
                    <tr>
                      <th rowspan="3" style="width: 18%;"></th>
                      <th colspan="2" class="Gray">{{ $t('label.planned') }}</th>
                      <th colspan="2" class="Gray">{{ $t('label.executed') }}</th>
                      <th rowspan="2" style="width: 8%; vertical-align: middle !important;">%</th>
                    </tr>
                    <tr>
                      <th style="color: #ff0a30;width: 18.5%;">{{ $t('label.full') }}</th>
                      <th style="color: #91a849;width: 18.5%;">{{ $t('label.empty') }}</th>
                      <th style="color: #ff0a30;width: 18.5%;">{{ $t('label.full') }}</th>
                      <th style="color: #91a849;width: 18.5%;">{{ $t('label.empty') }}</th>
                    </tr>
                    <tr>
                      <th>
                        <simpleDonut
                          :Animations="false"
                          :dataLabels="true"
                          :Series="SeriesPlannedVsExecute(PlannedJson, 'Full')" 
                          :Colors="ColorPlannedVsExecute" 
                          :Categories="LabelsPlannedVsExecute"
                          :Tooltip="TooltipPlannedVsExecute"
                          Height="210px"
                          :isDonut="false"
                        />
                      </th>
                      <th>
                        <simpleDonut
                          :Animations="false"
                          :dataLabels="true"
                          :Series="SeriesPlannedVsExecute(PlannedJson, 'Empty')" 
                          :Colors="ColorPlannedVsExecute" 
                          :Categories="LabelsPlannedVsExecute"
                          :Tooltip="TooltipPlannedVsExecute"
                          Height="210px"
                          :isDonut="false"
                        />
                      </th>
                      <th>
                        <simpleDonut
                          :Animations="false"
                          :dataLabels="true"
                          :Series="SeriesPlannedVsExecute(ExecutedJson, 'Full')" 
                          :Colors="ColorPlannedVsExecute" 
                          :Categories="LabelsPlannedVsExecute"
                          :Tooltip="TooltipPlannedVsExecute"
                          Height="210px"
                          :isDonut="false"
                        />
                      </th>
                      <th>
                        <simpleDonut
                          :Animations="false"
                          :dataLabels="true"
                          :Series="SeriesPlannedVsExecute(ExecutedJson, 'Empty')" 
                          :Colors="ColorPlannedVsExecute" 
                          :Categories="LabelsPlannedVsExecute"
                          :Tooltip="TooltipPlannedVsExecute"
                          Height="210px"
                          :isDonut="false"
                        />
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody class="text-center">
                    <tr>
                      <td class="text-left text-white" style="background: #ec7c30 !important;">{{ $t('label.DISCHARGE') }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[0]?.PlannedJson[0].CantFull) }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[0]?.PlannedJson[0].CantEmpty) }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[0]?.ExecutedJson[0].CantFull) }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[0]?.ExecutedJson[0].CantEmpty) }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[0]?.Percentage) }}</td>
                    </tr>
                    <tr>
                      <td class="text-left text-white" style="background: #4471c4 !important;">{{ $t('label.LOAD') }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[1]?.PlannedJson[0].CantFull) }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[1]?.PlannedJson[0].CantEmpty) }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[1]?.ExecutedJson[0].CantFull) }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[1]?.ExecutedJson[0].CantEmpty) }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[1]?.Percentage) }}</td>
                    </tr>
                    <tr>
                      <td class="text-left text-white" style="background: #0f5c57 !important;">{{ $t('label.shipToShip') }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[2]?.PlannedJson[0].CantFull) }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[2]?.PlannedJson[0].CantEmpty) }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[2]?.ExecutedJson[0].CantFull) }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[2]?.ExecutedJson[0].CantEmpty) }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[2]?.Percentage) }}</td>
                    </tr>
                    <tr>
                      <td class="text-left text-white" style="background: #5e548e !important;">{{ $t('label.shipLandShip') }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[3]?.PlannedJson[0].CantFull) }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[3]?.PlannedJson[0].CantEmpty) }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[3]?.ExecutedJson[0].CantFull) }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[3]?.ExecutedJson[0].CantEmpty) }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[3]?.Percentage) }}</td>
                    </tr>
                    <tr>
                      <td class="text-left text-white" style="background: #ff5b5b !important;">{{ $t('label.CANCELADO') }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[4]?.PlannedJson[0].CantFull) }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[4]?.PlannedJson[0].CantEmpty) }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[4]?.ExecutedJson[0].CantFull) }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[4]?.ExecutedJson[0].CantEmpty) }}</td>
                      <td>{{ Validation(MovsPlannedVsExecutedJson[4]?.Percentage) }}</td>
                    </tr>
                    <tr>
                      <td>TOTAL</td>
                      <th>{{ Validation(MovsPlannedVsExecutedJson[5]?.PlannedJson[0].CantFull) }}</th>
                      <th>{{ Validation(MovsPlannedVsExecutedJson[5]?.PlannedJson[0].CantEmpty) }}</th>
                      <th>{{ Validation(MovsPlannedVsExecutedJson[5]?.ExecutedJson[0].CantFull) }}</th>
                      <th>{{ Validation(MovsPlannedVsExecutedJson[5]?.ExecutedJson[0].CantEmpty) }}</th>
                      <th>{{ Validation(MovsPlannedVsExecutedJson[5]?.Percentage) }}</th>
                    </tr>
                  </tbody>
                </table>
             </div>
            </div>
          </div>
        </section>
        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page")+': '+CurrentPage(6, true) }} {{ $t("label.of") }} {{ TotalPage }}
            </p>
          </div>
        </section>
      </section>
      <!-- ///// Movements Per Crane ////// -->
      <section class="pdf">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo" />
          </div>
          <div class="title-container">
            <div>
              <strong>
                {{ titleCompany }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleVessel }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleFromItinerary }}
              </strong>
            </div>
            <div style="width: 82%">
              <table style="width: 100%;">
                <thead>
                  <tr>
                    <th>{{$t('label.type')+': '+itinerarySelected.TpVesselName}}</th>
                    <th>{{'MSSI: '+itinerarySelected.Mmsi}}</th>
                    <th>{{$t('label.gross')+': '+itinerarySelected.Gross}}</th>
                    <th>{{'TEUS: '+itinerarySelected.VesselTeus}}</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>
        <section class="body">
          <div class="general-info">
            <div class="info-content">
              <div class="title-report">
                {{ $t('label.MovementsPerCrane') }}
              </div>
              <div style="height:350px">
                <MultipleBar
                  height="350px"
                  :labels="CategoriesMultipleBar"
                  :series="SeriesMultipleBar"
                  positionLegend="bottom"
                  :getScales="getScales"
                />
              </div>
            </div>
          </div>
        </section>
        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page")+': '+CurrentPage(BulkCarrier==1?7:4, true) }} {{ $t("label.of") }} {{ TotalPage }}
            </p>
          </div>
        </section>
      </section>
      <!-- ///// Movements and Inactivity Times ////// -->
      <section class="pdf">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo" />
          </div>
          <div class="title-container">
            <div>
              <strong>
                {{ titleCompany }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleVessel }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleFromItinerary }}
              </strong>
            </div>
            <div style="width: 82%">
              <table style="width: 100%;">
                <thead>
                  <tr>
                    <th>{{$t('label.type')+': '+itinerarySelected.TpVesselName}}</th>
                    <th>{{'MSSI: '+itinerarySelected.Mmsi}}</th>
                    <th>{{$t('label.gross')+': '+itinerarySelected.Gross}}</th>
                    <th>{{'TEUS: '+itinerarySelected.VesselTeus}}</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>
        <section class="body">
          <div class="general-info">
            <div class="title-report mt-2">
              {{ $t('label.MovementsAndInactivityTimePerCrane') }}
            </div>
            <div class="info-content mt-2">
              <div>
                <table class="table table-bordered text-center">
                  <thead>
                    <tr>
                      <th style="width: 15%;"></th>
                      <th v-for="Crane in VisitCranesOpsJson" :style="Crane.CraneAlias=='TOTAL'?'width:'+CraneWidth+';background:#ededed !important;':'width:'+CraneWidth">
                        {{ Crane.CraneAlias }}
                      </th>
                    </tr>
                    <tr>
                      <th>
                        <div style="height: 190px;" class="d-flex align-content-center flex-wrap">
                          <div class="d-flex align-items-center justify-content-center">
                            <span class="circle mr-1" style="background: #002060;"></span>{{ $t('label.Operations') }}
                          </div>
                          <div class="d-flex align-items-center justify-content-center">
                            <span class="circle mr-1" style="background: #f59546;"></span>{{ $t('label.Inactivity') }}
                          </div>
                        </div>
                      </th>
                      <th v-for="Crane in VisitCranesOpsJson" :class="Crane.CraneAlias=='TOTAL'?'Gray':''">
                        <simpleDonut
                          :Animations="false"
                          :dataLabels="true"
                          :Series="SeriesMovementsAndInactivity(Crane)" 
                          :Colors="ColorMovementsAndInactivity" 
                          :Categories="LabelsMovementsAndInactivity"
                          :Tooltip="TooltipMovementsAndInactivity(Crane)"
                          Height="190px"
                          :isDonut="false"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-left">{{ $t('label.dateStartOperations') }}</td>
                      <td v-for="Crane in VisitCranesOpsJson" :class="Crane.CraneAlias=='TOTAL'?'Gray':''">
                        {{ Crane.BeginOpsDate?DateCrane(Crane.BeginOpsDate):'' }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">{{ $t('label.dateEndOperations') }}</td>
                      <td v-for="Crane in VisitCranesOpsJson" :class="Crane.CraneAlias=='TOTAL'?'Gray':''">
                        {{ Crane.LastOpsDate?DateCrane(Crane.LastOpsDate):'' }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">{{ $t('label.CraneHrs') }}</td>
                      <td v-for="Crane in VisitCranesOpsJson" :class="Crane.CraneAlias=='TOTAL'?'Gray':''">
                        {{ Crane.CraneHoursDs?Crane.CraneHoursDs:'00:00' }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">{{ $t('label.HoursDelays') }}</td>
                      <td v-for="Crane in VisitCranesOpsJson" :class="Crane.CraneAlias=='TOTAL'?'Gray':''">
                        {{ Crane.DelaysHoursDs?Crane.DelaysHoursDs:'00:00' }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">{{ $t('label.movements') }}</td>
                      <td v-for="Crane in VisitCranesOpsJson" :class="Crane.CraneAlias=='TOTAL'?'Gray':''">
                        {{ Crane.CantMovs?Crane.CantMovs:'0' }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">{{ $t('label.NetHrs') }}</td>
                      <td v-for="Crane in VisitCranesOpsJson" :class="Crane.CraneAlias=='TOTAL'?'Gray':''">
                        {{ Crane.NetHoursDs?Crane.NetHoursDs:'00:00' }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">{{ $t('label.MovsXHrNet') }}</td>
                      <td v-for="Crane in VisitCranesOpsJson" :class="Crane.CraneAlias=='TOTAL'?'Gray':''">
                        {{ Crane.NetMovsXHour?Crane.NetMovsXHour:'0' }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">{{ $t('label.MovsXHrGross') }}</td>
                      <td v-for="Crane in VisitCranesOpsJson" :class="Crane.CraneAlias=='TOTAL'?'Gray':''">
                        {{ Crane.GrossMovsXHour?Crane.GrossMovsXHour:'0' }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">{{ $t('label.inactivityTime') }}</td>
                      <td v-for="Crane in VisitCranesOpsJson" :class="Crane.CraneAlias=='TOTAL'?'Gray':''">
                        {{ Crane.InactiveHoursDs?Crane.InactiveHoursDs:'00:00' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page")+': '+CurrentPage(BulkCarrier==1?8:5, true) }} {{ $t("label.of") }} {{ TotalPage }}
            </p>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
  import {gantt} from 'dhtmlx-gantt';
  import { mapState } from "vuex";
  import moment from 'moment';
  import { DateFormater } from "@/_helpers/funciones";
  import DataStorage from "@/_helpers/localStorage";
  import simpleDonut from '@/pages/situacion-operativa-actual/components/simpleDonut';
  import barHorizontalNew from "@/pages/situacion-operativa-actual/components/barHorizontalNew";
  import barVerticalNew from "@/pages/situacion-operativa-actual/components/barVerticalNew";
  import MultipleBar from "@/pages/situacion-operativa-actual/components/multipleBarNew";

  function data() {
    return {
      ExecutedJson: [],
      PlannedJson: [],
      Month: {
        january: this.$t('label.january'),
        february: this.$t('label.february'),
        march: this.$t('label.march'),
        april: this.$t('label.april'),
        may: this.$t('label.may'),
        june: this.$t('label.june'),
        july: this.$t('label.july'),
        august: this.$t('label.august'),
        september: this.$t('label.september'),
        octuber: this.$t('label.octuber'),
        november: this.$t('label.november'),
        december: this.$t('label.december')
      },
    };
  }

  function created() {
    this.$i18n.locale = DataStorage.getLocale() || "es";
  }
  //methods
  function CurrentPage(page, Condition) {
    let NroPage = page;
    if (Condition) {
      if (this.NotProgrammed.length>0) {
        NroPage = NroPage + 1;
      }
      if (this.ProdByDayLapseIndex.length>1) {
        NroPage = NroPage + (this.ProdByDayLapseIndex.length-1);
      }
      return NroPage;
    } else {
      if (this.NotProgrammed.length>0) {
        NroPage = NroPage + 1;
      }
    }
    return NroPage;
  }

  function getName(item) {
    let _lang = this.$i18n.locale;
    return _lang == 'en' ? (item.NameEn || '') : (item.NameEs || '');
  }

  function getHour(Hour) {
    return Hour ?? '00:00';
  }

  function getPercentage(Percentage) {
    return Percentage ?? 0;
  }

  function getDate(date) {
    return date ? DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(date)) : '-';
  }

  function getDateHour(date) {
    return date ? DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatDateTimeWithSlash(date)) : '';
  }

  function SeriesSizeType(type){
    let data = [];
    if (type) {
      data.push(type?.StandardJson?.[0]?.CantTotal ?? 0);
      data.push(type.HighCubeJson?.[0]?.CantTotal ?? 0);
      data.push(type.ReeferJson?.[0]?.CantTotal ?? 0);
      data.push(type.SpecialJson?.[0]?.CantTotal ?? 0);
      data.push(type.TankJson?.[0]?.CantTotal ?? 0);
    }
    return data;
  }

  function PercentageDischarge(type) {
    if (type) {
        let Total = this.TypeJson[0].HighCubeJson[0].CantTotal+this.TypeJson[0].ReeferJson[0].CantTotal+
          this.TypeJson[0].SpecialJson[0].CantTotal+this.TypeJson[0].StandardJson[0].CantTotal+this.TypeJson[0].TankJson[0].CantTotal;
        return ((type.CantTotal/Total)*100).toFixed(2)+' %';
    }else{
        return 0+' %';
    }
  }

  function PercentageLoad(type) {
    if (type) {
        let Total = this.TypeJson[1].HighCubeJson[0].CantTotal+this.TypeJson[1].ReeferJson[0].CantTotal+
          this.TypeJson[1].SpecialJson[0].CantTotal+this.TypeJson[1].StandardJson[0].CantTotal+this.TypeJson[1].TankJson[0].CantTotal;
        return ((type.CantTotal/Total)*100).toFixed(2)+' %';
    }else{
      return 0+' %';
    }
  }

  function SeriesPlannedVsExecute(Val, Condition){
    let data = [];
    if (Val.length!=0&&Condition=='Full') {
      data.push(Val[0].CantFull);
      data.push(Val[1].CantFull);
    };
    if (Val.length!=0&&Condition=='Empty') {
      data.push(Val[0].CantEmpty);
      data.push(Val[1].CantEmpty);
    };
    return data;
  }

  function Validation(val) {
    if (val) {
        return val;
    } else {
        return 0;
    }
  }

  function SeriesMovementsAndInactivity(Crane){
    let data = [];
    if (Crane) {
        data.push(Crane?.CraneMinutes?Crane.CraneMinutes:0);
        data.push(Crane?.InactiveMinutes?Crane.InactiveMinutes:0);
    }
    return data;
  }

  function TooltipMovementsAndInactivity(CranesOps) {
    return{
      y: {
        formatter: function (val) {
          let tooltip = ''
          if (val==CranesOps.CraneMinutes) {
            tooltip = CranesOps.CraneHoursDs;
          }
          if (val==CranesOps.InactiveMinutes) {
            tooltip = CranesOps.InactiveHoursDs;
          }
          return tooltip;
        }
      }
    }
  }

  function DateCrane(date) {
    return DateFormater.formatDateTimeWithSlash(date);
  }

  //Computeds:
  function TotalType() {
    let Total = {
      StandardCant20: 0,
      StandardCant40: 0,
      StandardCant45: 0,
      StandardCantTotal: 0,
      HighCubeCant20: 0,
      HighCubeCant40: 0,
      HighCubeCant45: 0,
      HighCubeCantTotal: 0,
      ReeferCant20: 0,
      ReeferCant40: 0,
      ReeferCant45: 0,
      ReeferCantTotal: 0,
      SpecialCant20: 0,
      SpecialCant40: 0,
      SpecialCant45: 0,
      SpecialCantTotal: 0,
      TankCant20: 0,
      TankCant40: 0,
      TankCant45: 0,
      TankCantTotal: 0,
    }
    this.TypeJson.map(item => {
      Total.StandardCant20 += item.StandardJson[0].Cant20;
      Total.StandardCant40 += item.StandardJson[0].Cant40;
      Total.StandardCant45 += item.StandardJson[0].Cant45,
      Total.StandardCantTotal += item.StandardJson[0].CantTotal;
      Total.HighCubeCant20 += item.HighCubeJson[0].Cant20;
      Total.HighCubeCant40 += item.HighCubeJson[0].Cant40;
      Total.HighCubeCant45 += item.HighCubeJson[0].Cant45;
      Total.HighCubeCantTotal += item.HighCubeJson[0].CantTotal;
      Total.ReeferCant20 += item.ReeferJson[0].Cant20;
      Total.ReeferCant40 += item.ReeferJson[0].Cant40;
      Total.ReeferCant45 += item.ReeferJson[0].Cant45;
      Total.ReeferCantTotal += item.ReeferJson[0].CantTotal;
      Total.SpecialCant20 += item.SpecialJson[0].Cant20;
      Total.SpecialCant40 += item.SpecialJson[0].Cant40;
      Total.SpecialCant45 += item.SpecialJson[0].Cant45;
      Total.SpecialCantTotal += item.SpecialJson[0].CantTotal;
      Total.TankCant20 += item.TankJson[0].Cant20;
      Total.TankCant40 += item.TankJson[0].Cant40;
      Total.TankCant45 += item.TankJson[0].Cant45;
      Total.TankCantTotal += item.TankJson[0].CantTotal;
    })
    return Total;
  }

  function ProdByDayLapseDetailJson() {
    if (Object.entries(this.ProdByDayLapseJson).length !== 0) {
      return this.ProdByDayLapseJson.DetailJson;
    }else{
      return [];
    }
  }

  function ProdByDayLapseIndex() {
    if (Object.entries(this.ProdByDayLapseJson).length !== 0) {
      return this.ProdByDayLapseJson.index;
    }else{
      return [{DetailJson: []}];
    }
  }

  function ProdByDayLapseHeaderJson() {
    if (Object.entries(this.ProdByDayLapseJson).length !== 0) {
      return this.ProdByDayLapseJson.HeaderJson;
    }else{
      return [];
    }
  }

  function QuantityCrane() {
    if (this.ProdByDayLapseDetailJson.length!=0&&this.ProdByDayLapseDetailJson[0].DelaysJson) {
        return this.ProdByDayLapseDetailJson[0].DelaysJson.length!=0?this.ProdByDayLapseDetailJson[0].DelaysJson.length:0;
    } else {
        return 0
    }
  }

  function ColorSizeType(){
    let data = ['#3492b0', '#da8368', '#18cdd6', '#ffc000', '#92cddc'];
    return data;   
  }

  function LabelsSizeType(){
    let data = [this.$t('label.Estandar'), 'HIGH CUBE', this.$t('label.Reefers'), this.$t('label.special'), this.$t('label.Tank')];
    return data;
  }

  function TooltipSizeType() {
    return{
        y: {
            formatter: function (val) {
                return val;
            }
        }
    }
  }

  function seriesLineSize(){
    let data = [
      {
        label : '20',
        data : [],
        backgroundColor: '#00343f',
      },
      {
        label : '40',
        data : [],
        backgroundColor: '#ffbf67',
      },
      {
        label : '45',
        data : [],
        backgroundColor: '#e9b7a6',
      },
    ];
    this.ShippingLineJson.map(item => {
      data[0].data.push(item.Cant20Ft);
      data[1].data.push(item.Cant40Ft);
      data[2].data.push(item.Cant40Ft);
    });
    return data;
  }

  function categoriesBar(){
    let data = [];
    this.ShippingLineJson.map(item => {
      data.push(item.ShippingLineCode);
    })
    return data;
  }

  function seriesLinePortActivity(){
    let data = [
      {
        label : 'EXPORTACION',
        data : [],
        backgroundColor: '#52a3a6',
      },
      {
        label : 'IMPORTACION',
        data : [],
        backgroundColor: '#606060',
      },
      {
        label : 'BUQUE-TIERRA-BUQUE',
        data : [],
        backgroundColor: '#5e548e',
      },
      {
        label : 'BUQUE-BUQUE',
        data : [],
        backgroundColor: '#007a7b',
      },
    ];
    this.ShippingLineJson.map(item => {
      data[0].data.push(item.CantExport);
      data[1].data.push(item.CantImport);
      data[2].data.push(item.CantShipLandShip);
      data[3].data.push(item.CantShipShip);
    });
    return data;
  }

  function TotalShippingLine() {
    let Total = {
      Cant20Ft: 0,
      Cant40Ft: 0,
      Cant45Ft: 0,
      CantExport: 0,
      CantImport: 0,
      CantShipLandShip: 0,
      CantShipShip: 0,
    };
    this.ShippingLineJson.map(item => {
      Total.Cant20Ft+=item.Cant20Ft;
      Total.Cant40Ft+=item.Cant40Ft;
      Total.Cant45Ft+=item.Cant45Ft;
      Total.CantExport+=item.CantExport;
      Total.CantImport+=item.CantImport;
      Total.CantShipLandShip+=item.CantShipLandShip;
      Total.CantShipShip+=item.CantShipShip;
    })
    return Total;
  }

  function ColorPlannedVsExecute(){
    let data = ['#ec7c30', '#4471c4'];
    return data;   
  }

  function LabelsPlannedVsExecute(){
    let data = [this.$t('label.DISCHARGE'), this.$t('label.LOAD')];
    return data;
  }

  function TooltipPlannedVsExecute() {
    return{
        y: {
            formatter: function (val) {
                return val;
            }
        }
    }
  }

  function CategoriesMultipleBar() {
    let data = [];
    this.VisitCranesOpsJson.map(item => {
      if (item.CraneAlias!='TOTAL') {
        data.push(item.CraneAlias);
      };
    });
    return data;
  }

  function SeriesMultipleBar(){
    let data = [
      {
        label: this.$t("label.DischargeMovements"),
        data: [],
        backgroundColor: '#52a3a6',
        stack: 'Stack 0',
        yAxisID: 'y',
      },
      {
        label: this.$t("label.LoadMovements"),
        data: [],
        backgroundColor: '#2196f3',
        stack: 'Stack 0',
        yAxisID: 'y',
      },
      {
        label: this.$t("label.HatchMovements"),
        data: [],
        backgroundColor: '#26E7A6',
        stack: 'Stack 0',
        yAxisID: 'y',
      },
      {
        label: this.$t("label.averageMovementsPerHrs"),
        data: [],
        backgroundColor: '#606060',
        stack: 'Stack 1',
        yAxisID: 'y1',
      },
    ];
    this.VisitCranesOpsJson.map(item => {
      if (item.CraneAlias!='TOTAL') {
        data[0].data.push(item.CantDischarge?item.CantDischarge:0);
        data[1].data.push(item.CantLoad?item.CantLoad:0);
        data[2].data.push(item.CantCoverMovs?item.CantCoverMovs:0);
        data[3].data.push(item.GrossMovsXHour?item.GrossMovsXHour:0);
      };
    });
    return data;
  }

  function getScales(){
    return {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        display: true,
        position: 'left',
        title: {
          display: true,
          text: this.$t("label.MovsNumbers") // array so it becomes multi lined
        },
      },
      y1: {
        display: true,
        stacked: true,
        position: 'right',
        ticks:{
          color: "#e96418",
        },
        grid: {
          color: "#e96418",
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
        title: {
          display: true,
          color: '#e96418',
          text: this.$t("label.averageMovementsPerHrs") // array so it becomes multi lined
        },
      },
    }
  }

  function ColorMovementsAndInactivity(){
    let data = ['#002060', '#f59546'];
    return data;   
  }

  function LabelsMovementsAndInactivity(){
    let data = [this.$t('label.Operations'), this.$t('label.Inactivity')];
    return data;
  }

  function CraneWidth() {
    let length = (85/this.VisitCranesOpsJson.length).toFixed(2);
    return length+'%';
  }

  function hasLogo() {
    return `${this.$store.getters["connection/getBase"]}${
      this.user.LogoRoute
        ? this.user.LogoRoute.replace("Public/", "", null, "i")
        : ""
    }`;
  }

  function titleCompany() {
    let CompanyName = this.user.CompanyName;
    let CompanyRif = this.user.CompanyRif;
    let BranchName = this.branch.BranchName;

    return `${CompanyName} ${CompanyRif} - ${BranchName}`;
  }

  function titleVessel() {
    let VesselName = this.itinerarySelected.VesselName;
    let Imo = this.itinerarySelected.Imo;
    let CountryName = this.itinerarySelected.CountryName;
    let berth = this.$t('label.berth');
    let BerthName = this.itinerarySelected.BerthName;

    return `${VesselName} ${Imo}, ${CountryName}, ${berth}: ${BerthName}`;
  }

  function titleFromItinerary() {
    let previousDate;
    let laterDate;
    let fecha = "";
    let voyage = this.$t('label.voyage')+": "+this.itinerarySelected.Voyage;

    if(this.itinerarySelected.Ata!=undefined){ 
      fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
      previousDate = "ATA:"+fecha;
    }

    if(this.itinerarySelected.Ata==undefined){
      fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
      previousDate = "ETA:"+fecha;
    }

    if(this.itinerarySelected.Atd!=undefined){  
      fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
      laterDate = "ATD:"+fecha;
    }

    if(this.itinerarySelected.Atd==undefined){
      fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Etd);
      laterDate = "ETD:"+fecha;
    }

    return `${previousDate}, ${laterDate} - ${voyage}`;
  }

  function currentDateTime() {
    let today = new Date();
    let month =
      today.getMonth() + 1 < 10
        ? `0${today.getMonth() + 1}`
        : today.getMonth() + 1;
    let date = today.getDate() + "/" + month + "/" + today.getFullYear();
    let time = today.getHours() + ":" + today.getMinutes();

    return date + " " + time;
  }

  function loginName() {
    return this.user ? `${this.$t("label.USUARIO")}: ${this.user.Login}` : "";
  }

  export default {
    data,
    props: {
      Color: {
        type: Object,
        default () {
          return {data: [], links: []}
        }
      },
      tasks: {
        type: Object,
        default () {
          return {data: [], links: []}
        }
      },
      NotProgrammed:{
        type: Array,
        default (){
          return []
        }
      },
      itinerarySelected: {
        type: Object,
        default () {
          return {}
        }
      },
      BulkCarrier: {
        type: Number,
        default () {
          return 0
        }
      },
      TotalPage: {
        type: Number,
        default () {
          return 0
        }
      },
      VisitSummaryJson: {
        type: Object,
        default () {
          return {}
        }
      },
      FgDate: {
        type: Boolean,
        default () {
          return true
        }
      },
      ProdByDayLapseJson: {
        type: Object,
        default () {
          return {}
        }
      },
      SizeJson: {
        type: Array,
        default () {
          return []
        }
      },
      TypeJson: {
        type: Array,
        default () {
          return []
        }
      },
      ShippingLineJson: {
        type: Array,
        default () {
          return []
        }
      },
      MovsPlannedVsExecutedJson: {
        type: Array,
        default () {
          return []
        }
      },
      VisitCranesOpsJson: {
        type: Array,
        default () {
          return []
        }
      },
    },
    created,
    components:{
      simpleDonut,
      barHorizontalNew,
      barVerticalNew,
      MultipleBar,
    },
    methods: {
      CurrentPage,
      getName,
      getHour,
      getPercentage,
      getDate,
      getDateHour,
      SeriesSizeType,
      PercentageDischarge,
      PercentageLoad,
      SeriesPlannedVsExecute,
      Validation,
      SeriesMovementsAndInactivity,
      TooltipMovementsAndInactivity,
      DateCrane,
    }, 
    computed: {
      ProdByDayLapseDetailJson,
      ProdByDayLapseIndex,
      ProdByDayLapseHeaderJson,
      QuantityCrane,
      TotalType,
      TotalShippingLine,
      CraneWidth,
      LabelsMovementsAndInactivity,
      ColorMovementsAndInactivity,
      getScales,
      SeriesMultipleBar,
      CategoriesMultipleBar,
      ColorPlannedVsExecute,
      LabelsPlannedVsExecute,
      TooltipPlannedVsExecute,
      seriesLinePortActivity,
      categoriesBar,
      seriesLineSize,
      LabelsSizeType,
      ColorSizeType,
      TooltipSizeType,
      titleCompany,
      titleVessel,
      titleFromItinerary,
      hasLogo,
      loginName,
      currentDateTime,
      ...mapState({
        user: (state) => state.auth.user,
        branch: state => state.auth.branch,
      }),
    },
    watch:{
      MovsPlannedVsExecutedJson: function (val) {
        if (val.length!=0) {
          val.map(item => {
            this.ExecutedJson.push(item.ExecutedJson[0]);
            this.PlannedJson.push(item.PlannedJson[0]);
          });
        };
      },
      tasks: async function(val) {
        gantt.config.show_unscheduled = true;
        gantt.config.autosize = "y";
        gantt.config.date_format = "%Y-%m-%d %H:%i";
        gantt.config.open_tree_initially = true;
        gantt.config.readonly = true;
        gantt.config.columns = [
          {name: "text", label: this.$t('label.activities'), tree: true, width: 250},
          {name: "Color", label: "", width: 25, align: "center"},
          {name: "start_date", label: this.$t('label.from'), width:80, align: "center"},
          {name: "end_date", label: this.$t('label.to'), width:80, align: "center"},
          {name: "Hrs", label: this.$t('label.duration')+'(HRS)', width: 80, align: "center"},
          {name: "HrsAcumulated", label: this.$t('label.Accumulated'), width: 80, align: "center"},
          {name: "Completed", label: this.$t('label.Completed'), width: 70, align: "center"},
          {name: "HrsInactive", label: this.$t('label.Inactivity'), width: 70, align: "center"},
        ];
        gantt.serverList("Color", this.Color.data);
        gantt.plugins({ 
          tooltip: false,
        });
        let Month = this.Month;
        let Maxlength = val.data.length-1;
        let zoomConfig = {
          levels: [
            {
              name:"month",
              min_column_width:40,
              scale_height: 20,
              scales:[
                {unit: "month", step: 1, format: function (date) {
                  let month = gantt.date.date_to_str('%F')(date);
                  month = month.toLowerCase();
                  let Year = gantt.date.date_to_str('%Y')(date);
                  return Month[month]+'-'+Year;
                }},
                {unit: "day", step: 1, format: "%j"}
              ]
            },
          ],
          startDate: val.data.length!=0 && val.data[0].start_date ? val.data[0].start_date : '',
          endDate: val.data.length!=0 && val.data[Maxlength].end_date ? val.data[Maxlength].end_date : '',
          useKey: "ctrlKey",
          trigger: "wheel",
          element: function(){
            return gantt.$root.querySelector(".gantt_task");
          }
        }
        gantt.ext.zoom.init(zoomConfig);

        gantt.config.start_date = val.data.length!=0 && val.data[0].start_date ? val.data[0].start_date : '';
        gantt.config.end_date = val.data.length!=0 && val.data[Maxlength].end_date ? val.data[Maxlength].end_date : '';

        gantt.config.layout = {
          css: "gantt_container",
          rows:[
            {
              cols: [
                {
                  hide_empty: true, 
                  rows:[
                    {view: "grid"}
                  ]
                },
              ]
            },
            {resizer: true},
            {
              cols: [
                {
                  hide_empty: true, 
                  rows:[
                    {view: "timeline"}
                  ]
                },
              ]
            },
          ]
        };

        gantt.templates.grid_row_class =
          gantt.templates.task_row_class =
            gantt.templates.task_class = function (start, end, task) {
              var css = [];
              if(task.id){
                css.push("gantt_resource_" + task.id);
              }

              return css.join(" ");
            };
        gantt.attachEvent("onParse", function(){
          var styleId = "dynamicGanttStyles";
          var element = document.getElementById(styleId);
          if(!element){
            element = document.createElement("style");
            element.id = styleId;
            document.querySelector("head").appendChild(element);
          }
          var html = [];
          var resources = gantt.serverList("Color");

          resources.forEach(function(r){
            html.push(".gantt_row.gantt_resource_" + r.id + " .gantt_cell:nth-child(2) .gantt_tree_content{" +
              "background-color:"+r.progressColor+"; " +
              "}");
            html.push(".gantt_row.gantt_resource_" + r.id + " .gantt_cell .gantt_tree_content{" +
              "white-space: nowrap; min-width: 0; font-size: 0.5rem;" +
              "}");
          });
          element.innerHTML = html.join("");
        });
        gantt.templates.grid_header_class = function(columnName, column){
          return "size-05";
        };
        gantt.templates.scale_row_class = function(columnName, column){
          return "size-05";
        };
        gantt.templates.grid_folder  = function() {
          return "";
        };
        gantt.templates.grid_file = function() {
          return "";
        };
        gantt.templates.task_text=function(start, end, task){
          return "";
        };
        gantt.templates.grid_indent=function(task){
          return "<div style='width:10px; float:left; height:100%'></div>"
        };
        gantt.templates.date_grid = function(date, task, column){
          if(task.unscheduled){
            return '';
          }else if (task.Delay) {
            return '-'
          }else if (task.FgDateRange && column == 'end_date') {
              return '-'
          }else if (task.DateFinish && column == 'end_date') {
            return moment(task.DateFinish,'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm');
          }else{
            return gantt.date.date_to_str('%d/%m/%Y %H:%i')(date);
          }

        }

        gantt.init(this.$refs.ganttContainer);
        gantt.parse(this.$props.tasks);
      },
    },
  }
</script>
<style>
    @import "~dhtmlx-gantt/codebase/dhtmlxgantt.css";
    .gantt_task_line {
        border-radius: 14px !important;
    }
    .gantt_cell:nth-child(2) .gantt_tree_content{
			border-radius: 50%;
			width: 100%;
			height: 80%;
			margin: 5% 0;
			line-height: 230%;
		}
    .gantt_grid_data .gantt_row.odd:hover, .gantt_grid_data .gantt_row:hover,
      .gantt_grid_data .gantt_row.gantt_selected,
      .gantt_grid_data .gantt_row.odd.gantt_selected,
      .gantt_task_row.gantt_selected{
          background-color: #bdbdbd;
      }
    .size-06{
      font-size: 0.6rem;
    }
    .size-05{
      font-size: 0.5rem;
    }
</style>
<style scoped src="./pdf-styles.css"></style>
<style scoped>
.pdf-doc {
  color: #000 !important;
}
/* *************************************************** */
/* Canecera */
/* *************************************************** */
.pdf .header .title-container {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.pdf .header .title-container strong {
  margin-bottom: 0;
}

/* *************************************************** */
/* Ficha Tecnica */
/* *************************************************** */
.pdf .body .general-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.pdf .body .general-info .info-content {
  width: 95%;
  height: 640px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  /* border: 1px solid #000; */
}
.title-report {
  width: 100%;
  color: #293864;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
}
.pdf .body .general-info .info-content .info-gantt {
  width: 100%;
  /* height: 75%; */

  display: flex;
  flex-direction: column;
  align-items: center;
}
.circle{
    height: 15px;
    width: 15px;
    background: #666666;
    display: inline-block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.Gray{
  background: #ededed !important;
}
/* *************************************************** */
/* Tablas de Info */
/* *************************************************** */
.time-in-port-table{
  width: 100%;
  border-collapse: collapse;
  font-size: 8px;
}
table.time-in-port-table tr:last-child td {
  border-bottom: none !important;
}
table.time-in-port-table th{
  border-top: none !important;
  border-left: none !important;
  border-bottom: none !important;
}
table.time-in-port-table th:last-child {
  border-right: none !important;
}
table.time-in-port-table td:first-child {
  border-left: none !important;
  border-right: none !important;
}
table.time-in-port-table td:last-child {
  border-right: none !important;
}
</style>